
  import { Action } from "vuex-class";
  import { Component, Mixins, Ref, Vue } from "vue-property-decorator";
  import { FormBase, FormTextarea, FormMoney, FormSelect, FormError, FormDate, FormSubmit } from "@/components/forms";
  import { formatErrors } from "@/utils/formatters";
  import { bus } from "@/main";
  import { HasNotaryOptions } from "@/mixins/has-notary-options";
  import { Mandate, UpdateMandatePayload } from "@/store/modules/mandate.store";

  @Component({
    components: {
      LayoutModal: () => import("@/layouts/components/modal.vue"),
      FormBase,
      FormTextarea,
      FormSelect,
      FormDate,
      FormError,
      FormSubmit,
      FormMoney,
    },
  })
  export default class updateActivityModal extends Mixins(HasNotaryOptions) {
    @Action("activity/patch") patchActivity!: (payload: ActivityPatchPayload) => Activity;
    @Action("mandate/patch") patchMandate!: (payload: UpdateMandatePayload) => Promise<Mandate>;

    @Ref() form!: FormClass;

    payload: any = {
      activity_id: NaN,
      memo: null,
      sold_for: null,
      buyer_notary: null,
      seller_notary: null,
      compromis_signed_at: null,
    };

    dirty = {
      memo: false,
      sold_for: false,
      seller_notary: false,
      buyer_notary: false,
      compromis_signed_at: false,
    };

    show = false;

    errorResponse: ErrorResponse = {
      status: 0,
      errors: [],
    };

    mounted() {
      bus.$off("show-update-list-dossier");
      bus.$on("show-update-list-dossier", (activity: any) => {
        this.getNotaryOptions();

        this.payload = {
          activity_id: activity.id,
          memo: activity.memo,
          sold_for: activity.sold_for,
          buyer_notary: activity.buyer_notary,
          seller_notary: activity.seller_notary,
          compromis_signed_at: activity.compromis_signed_at,
        };

        this.resetDirtyStatus();

        this.show = true;
      });
    }

    async submit(form: FormClass) {
      try {
        let promises: any = [];

        promises.push(this.handleActivitySubmit());

        await Promise.all(promises);

        this.$toast.open({ message: this.$t("views.dossier.update_success") as string, type: "success", position: "bottom-left" });

        this.handleClose();
      } catch (e) {
        this.errorResponse = formatErrors(e);
      } finally {
        form.reset();
      }
    }

    async handleActivitySubmit() {
      let payload: any = {};

      if (this.dirty.memo) {
        payload.memo = this.payload.memo;
      }

      if (this.dirty.sold_for) {
        payload.sold_for = this.payload.sold_for;
      }

      if (this.dirty.buyer_notary) {
        payload.buyer_notary = this.payload.buyer_notary;
      }

      if (this.dirty.seller_notary) {
        payload.seller_notary = this.payload.seller_notary;
      }

      if (this.dirty.compromis_signed_at) {
        payload.compromis_signed_at = this.payload.compromis_signed_at;
      }

      if (Object.values(payload).length === 0) {
        return new Promise<void>((resolve) => resolve());
      }

      return this.patchActivity({ id: this.payload.activity_id, ...payload });
    }

    resetDirtyStatus() {
      this.dirty = {
        memo: false,
        sold_for: false,
        buyer_notary: false,
        seller_notary: false,
        compromis_signed_at: false,
      };
    }

    handleClose() {
      this.errorResponse = {
        status: 0,
        errors: [],
      };

      this.show = false;

      this.resetDirtyStatus();
    }
  }
